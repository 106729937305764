const Scores = ({ status, scores, localTeam, visitorTeam }) => {

    return (
        <div className="scores">
            <div className={`team ${localTeam.data.name.toLowerCase()}`}>
                <div>
                    <p className="big">{localTeam.data.name}</p>
                    <p>{(status === 'NS') ? '-' : scores.localteam_score}</p>
                </div>
                <div><img src={localTeam.data.logo_path} alt={localTeam.data.name} /></div>
            </div>
            <div className={`team ${visitorTeam.data.name.toLowerCase()}`}>
                <div>
                    <p className="big">{visitorTeam.data.name}</p>
                    <p>{(status === 'NS') ? '-' : scores.visitorteam_score}</p>
                </div>
                <div><img src={visitorTeam.data.logo_path} alt={localTeam.data.name} /></div>
            </div>
        </div>
    )
}

export default Scores