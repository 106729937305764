// utilities
import Utilities from "../../Utilities";

const ArticleSingle = ({ article, isShown }) => {

  return (
    <div className={`article-single slide slide-right has-header ${isShown ? "active" : ""} ${Utilities.getSourceFromURL(article.href)}` }>
      {article.href !== '' &&
        <iframe key={article.id} src={article.href} height="100%" width="100%" title="iframe" id={article.id} />
      }
    </div>
  );
}

export default ArticleSingle;