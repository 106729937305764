import { useState } from "react";

/* mui */
import {
    Snackbar,
    FormGroup,
    FormControlLabel,
    Switch,
    Checkbox,
    TextField,
    IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const ArticlesFilter = ({ onSearchSubmit, isShown }) => {

    const [snackOpen, setSnackOpen] = useState(false);
    const [coverInput, setCoverInput] = useState(false);
    const [keywordInput, setKeywordInput] = useState('');
    const [sourcesInput, setSourcesInput] = useState(
        {
            celtsarehere: true,
            readceltic: true,
            thecelticbhoys: true,
            thecelticblog: true,
            thecelticstar: true,
            videocelts: true
        });

    const filterArticles = () => {
        let sourcesChecked = document.querySelectorAll('input[name="sources"]:checked').length;
        if (sourcesChecked === 0) {
            setSnackOpen(true);
        } else {
            onSearchSubmit(
                {
                    cover: coverInput,
                    keyword: keywordInput,
                    sources: sourcesInput
                })
        }
    }

    const resetFilters = () => {
        setCoverInput(false);
        setKeywordInput('');
        setSourcesInput({
            celtsarehere: true,
            readceltic: true,
            thecelticbhoys: true,
            thecelticblog: true,
            thecelticstar: true,
            videocelts: true
        });
    }

    const handleOnChange = (evt) => {
        if (evt.target.name === "sources") {
            setSourcesInput({ ...sourcesInput, [evt.target.value]: evt.target.checked })
        } else if (evt.target.name === "keyword") {
            setKeywordInput(evt.target.value);
        } else if (evt.target.name === "cover") {
            setCoverInput(evt.target.checked);
        }
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <>
            <div className={isShown ? "filters-box slide slide-bottom has-header active" : "filters-box slide slide-bottom has-header"}>
                <div className="wrapper">
                    <div className="field">
                        <h2>Only cover stories</h2>
                        <FormGroup>
                            <FormControlLabel control={<Switch name="cover" onChange={evt => handleOnChange(evt)} />} checked={coverInput} label={coverInput ? 'yes' : 'no'} />
                        </FormGroup>
                    </div>
                    <div className="field">
                        <h2>Filter sources</h2>
                        <FormGroup>
                            {Object.entries(sourcesInput).map(([key, data]) => (
                                <FormControlLabel key={key} control={<Checkbox name="sources" value={key} checked={data} onChange={evt => handleOnChange(evt)} />} label={key} />
                            ))}
                        </FormGroup>
                    </div>
                    <div className="field">
                        <h2>Search by keyword</h2>
                        <TextField name="keyword" label="type keyword" type="search" value={keywordInput} onChange={evt => handleOnChange(evt)} />
                    </div>
                    <div className="buttons">
                        <button className="primary" onClick={filterArticles}>apply</button>
                        <button className="secondary" onClick={resetFilters}>reset</button>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackOpen}
                autoHideDuration={3000}
                onClose={handleSnackClose}
                message="effettua almeno una selezione"
                action={action}
            />
        </>
    );
}

export default ArticlesFilter;
