import { Component } from 'react';

class Spinner extends Component {

  render() {
    return (
        <div id="spinner">
            <img src="/icons/spinner.svg" alt="Loading" />
        </div>
    );
  }
}

export default Spinner;