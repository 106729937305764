import Bench from "./Bench";
import Player from "./Player";

import { useTheme } from "@mui/material";

const Lineup = ({ isVisible, formations, lineup, bench, events, localTeam, visitorTeam }) => {

    const { localteam_formation, visitorteam_formation } = formations;

    const substitutions = events.filter(function (event) {
        return (event.type === 'substitution');
    });

    const theme = useTheme();

    let render = '';

    if (localteam_formation && visitorteam_formation) {

        let localTeamCounter = 1;
        let visitorTeamCounter = 1;

        const localTeamLines = [];
        const visitorTeamLines = [];

        const localTeamSplittedLines = localteam_formation.split('-');
        const visitorTeamSplittedLines = visitorteam_formation.split('-');

        const localTeamLineup = lineup.data.sort((a, b) => a.formation_position - b.formation_position).filter(function (player) {
            return player.team_id === localTeam.data.id;
        });
        const visitorTeamLineup = lineup.data.sort((a, b) => a.formation_position - b.formation_position).filter(function (player) {
            return player.team_id === visitorTeam.data.id;
        });

        function getLinePlayers(team, total, index) {
            let line = [];
            for (let p = 0; p < total; p++) {
                if (team === 'local') {
                    line.push(localTeamLineup[localTeamCounter]);
                    localTeamCounter++;
                } else if (team === 'visitor') {
                    line.push(visitorTeamLineup[visitorTeamCounter]);
                    visitorTeamCounter++;
                }
                if (team === 'local') {
                    localTeamLines[index] = line;
                } else if (team === 'visitor') {
                    visitorTeamLines[index] = line;
                }
            }
        }

        function getPlayerData(team, id) {
            let squad = [];
            if (team === 'local') {
                squad = localTeamLineup;
            } else if (team === 'visitor') {
                squad = visitorTeamLineup;
            }
            const players = squad.filter(function (player) {
                return player.player_id === id;
            });
            return players[0];
        }

        function playerSubstitution(id) {
            let substitution = false;
            Object.keys(substitutions).forEach(function (key) {
                if (parseInt(substitutions[key].related_player_id) === parseInt(id)) {
                    substitution = true;
                };
            });
            return substitution;
        }

        localTeamSplittedLines.map((line, index) => (
            getLinePlayers('local', parseInt(line), index)
        ));

        visitorTeamSplittedLines.map((line, index) => (
            getLinePlayers('visitor', parseInt(line), index)
        ));

        const teamStyle = (theme.media === 'mobile') ? {
            height: `calc(100% / ${localTeamLines.length + 1})`
        } : {
            width: `calc(100% / ${localTeamLines.length + 1})`
        };

        render =
            <>
                <div className="formation">
                    <strong>{localTeam.data.name}</strong> ({localteam_formation})
                </div>
                <div className="pitch">
                    {localTeamLineup.length > 0 &&
                        <div className="team">
                            <div className="teamline" style={teamStyle}>
                                <Player substitution={playerSubstitution(localTeamLineup[0].player_id)} data={getPlayerData('local', localTeamLineup[0].player_id)} />
                            </div>
                            {localTeamLines.map((line, index) => (
                                <div className="teamline" key={index} style={teamStyle}>
                                    {line.map(player => (
                                        <Player key={player.player_id} substitution={playerSubstitution(player.player_id)} playersInLine={line.length} data={getPlayerData('local', player.player_id)} />
                                    ))}
                                </div>
                            ))}
                        </div>
                    }
                    {visitorTeamLineup.length > 0 &&
                        <div className="team">
                            <div className="teamline" style={teamStyle}>
                                <Player substitution={playerSubstitution(localTeamLineup[0].player_id)} data={getPlayerData('visitor', visitorTeamLineup[0].player_id)} />
                            </div>
                            {visitorTeamLines.map((line, index) => (
                                <div className="teamline" key={index} style={teamStyle}>
                                    {line.map(player => (
                                        <Player key={player.player_id} substitution={playerSubstitution(player.player_id)} playersInLine={line.length} data={getPlayerData('visitor', player.player_id)} />
                                    ))}
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="formation">
                    <strong>{visitorTeam.data.name}</strong> ({visitorteam_formation})
                </div>
                <Bench bench={bench} substitutions={substitutions} localTeam={localTeam} visitorTeam={visitorTeam} />
            </>
    } else {
        render =
            <>
                <div className="placeholder">
                    lineup not available,<br />try later
                </div>
            </>
    }

    return (
        <div className={isVisible ? `lineup active` : `lineup not-active`}>
            <h2>Lineups</h2>
            {render}
        </div>
    )

}

export default Lineup