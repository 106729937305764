const Tabs = ({ tabs, onTabClick }) => {

    return (
        <div className="tabs">
            {tabs.map((tab, index) => (
                <div className={tab.visible ? `tab active`: `tab`} key={index} onClick={() => onTabClick(tab.label)} >
                    {tab.label}
                </div>
            ))}
        </div>
    )
}

export default Tabs