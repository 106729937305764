const Scorers = ({ status, events, clips, onEventClick, isVisible }) => {

    // console.log(clips);

    const scorers = events.filter(function (event) {
        return event.type === 'goal' || event.type === 'penalty' || event.type === 'own-goal';
    });

    function clipsIsAvailable(id) {
        if (clips && clips.includes(String(id)) && status === 'FT') {
            return true;
        }
        return false;
    }

    function getGoalLabel(goal) {
        let goalLabel = goal.player_name;
        switch (goal.type) {
            case 'penalty':
                goalLabel += ' (rigore) '
                break;
            case 'own-goal':
                goalLabel += ' (autogoal) '
                break;
            default:

        }
        return goalLabel;
    }

    return (
        <div className="scorers">
            {scorers.sort((a, b) => a.id - b.id).map(goal => (
                <div key={goal.id} className={`goal ${goal.team.data.name.toLowerCase()}`}>
                    {isVisible &&
                        <>
                            <div><span>{`${goal.minute}'`}</span></div>
                            <div><span>{getGoalLabel(goal)}</span></div>
                            <div>{clipsIsAvailable(goal.id) && <img src="icons/hd.svg" alt="video" onClick={() => onEventClick(goal.id)} />}</div>
                        </>
                    }
                    {!isVisible &&
                        <>
                            <div><span>{`${goal.minute}'`}</span></div>
                            <div><span>loading...</span></div>
                            <div><img src="icons/ripple.svg" alt="loading" /></div>
                        </>
                    }
                </div>
            ))}
        </div>
    )
}

export default Scorers