import { useState } from "react";
import Utilities from "../../Utilities";

const ArticlePreview = ({ media, cover, article }) => {

    const [isLoading, setIsLoading] = useState(true);
    const imagePath = (isLoading) ? "https://plchldr.co/i/600x400?&bg=ededed&fc=black&text=only-celtic-fans" : `${process.env.REACT_APP_BACK_URL}/images/${article.thumb}.webp`;

    return (
        <div className={`preview article-preview ${article.cover === "1" ? "cover" : "default"}`}>
            <img className={`${article.cover === "1" ? "cover" : "default"}`} onLoad={() => { setIsLoading(false) }} src={imagePath} alt={article.text}></img>
            {(media === "mobile" || !cover) &&
                <div className="text">
                    <div className="copy">
                        <p className="little">{Utilities.getDifferenceBetweenDates(article.created_at, false)} - {Utilities.getSourceFromURL(article.href)}</p>
                        <p>{article.title}</p>
                    </div>
                </div>
            }
            {media === "desktop" && cover &&
                <div className="text-float">
                    <div className="copy">
                        <p className="little">{Utilities.getDifferenceBetweenDates(article.created_at, false)} - {Utilities.getSourceFromURL(article.href)}</p>
                        <p>{article.title}</p>
                    </div>
                </div>
            }
        </div>
    );
}

export default ArticlePreview;


