const Stats = ({ isVisible, stats, localTeam, visitorTeam }) => {

    let rows = [];
    let render = '';

    if (stats.data[0] && stats.data[1]) {

        const localTeamStats = stats.data[0];
        const visitorTeamStats = stats.data[1];

        rows =
            [
                { label: 'tiri totali', value: (localTeamStats.shots && localTeamStats.shots.total && visitorTeamStats.shots && visitorTeamStats.shots.total) ? [localTeamStats.shots.total, visitorTeamStats.shots.total] : [0, 0] },
                { label: 'tiri in porta', value: (localTeamStats.shots && localTeamStats.shots.ongoal && visitorTeamStats.shots && visitorTeamStats.shots.ongoal) ? [localTeamStats.shots.ongoal, visitorTeamStats.shots.ongoal] : [0, 0] },
                { label: 'possesso palla', value: (localTeamStats.possessiontime && localTeamStats.possessiontime) ? [`${localTeamStats.possessiontime}%`, `${visitorTeamStats.possessiontime}%`] : ['0', '0'] },
                { label: 'passaggi totali', value: (localTeamStats.passes && visitorTeamStats.passes) ? [localTeamStats.passes.total, visitorTeamStats.passes.total] : [0, 0] },
                { label: 'precisione passaggi', value: (localTeamStats.passes && visitorTeamStats.passes) ? [`${Math.round(localTeamStats.passes.percentage)}%`, `${Math.round(visitorTeamStats.passes.percentage)}%`] : [0, 0] },
                { label: 'calci d\'angolo', value: (localTeamStats.corners && visitorTeamStats.corners) ? [localTeamStats.corners, visitorTeamStats.corners] : [0, 0] },
                { label: 'fuori gioco', value: (localTeamStats.offsides && visitorTeamStats.offsides) ? [localTeamStats.offsides, visitorTeamStats.offsides] : [0, 0] },
                { label: 'falli commessi', value: (localTeamStats.fouls && visitorTeamStats.fouls) ? [localTeamStats.fouls, visitorTeamStats.fouls] : [0, 0] },
                { label: 'cartellini gialli', value: (localTeamStats.yellowcards && visitorTeamStats.yellowcards) ? [localTeamStats.yellowcards, visitorTeamStats.yellowcards] : [0, 0] },
                { label: 'cartellini rossi', value: (localTeamStats.redcards && visitorTeamStats.redcards) ? [localTeamStats.redcards, visitorTeamStats.redcards] : [0, 0] },
            ];

        render =
            <>
                {rows.map(row => (
                    <div className="row" key={row.label}>
                        <table>
                            <tbody>
                                {row.value &&
                                    <tr>
                                        <td className={`big ${localTeam.data.name.toLowerCase()}`}>{row.value[0]}</td>
                                        <td>{row.label}</td>
                                        <td className={`big ${visitorTeam.data.name.toLowerCase()}`}>{row.value[1]}</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                ))}
            </>

    } else {
        rows = null;
        render =
            <>
                <div className="placeholder">
                    nessuna statistica disponibile,<br />riprova più tardi
                </div>
            </>
    }

    return (
        <div className={isVisible ? `stats active` : `stats not-active`}>
            <h2>Statistiche</h2>
            {render}
        </div>
    )

}

export default Stats