import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'

// components
import Header from "../components/Header";
import Spinner from "../components/Spinner";

// screens
import PlayersList from "../screens/team/List";
import PlayersFilter from "../screens/team/Filters";
import PlayerSingle from "../screens/team/Single";

// node_modules
import axios from "axios";

const Team = () => {

  // manage stored data
  const [playersList, setPlayersList] = useState([]);
  const [playerSingle, setPlayerSingle] = useState({ data: [], stats: [] });
  const [headerTitle, setHeaderTitle] = useState('team');
  const [appliedFilters, setAppliedFilters] = useState({ keyword: '', positions: [1, 2, 3, 4] });

  // manage show/hide screens
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);

  // history
  const history = useHistory();

  // load players
  useEffect(() => {
    const url = `https://soccer.sportmonks.com/api/v2.0/squad/season/19735/team/53?api_token=${process.env.REACT_APP_SPORTMONKS_TOKEN}&include=player`;
    setShowSpinner(true);
    axios.get(url)
      .then(response => {
        setPlayersList(response.data.data);
        setShowFilters(false);
        setShowSpinner(false);
      })
  }, [])

  // set title
  useEffect(() => {
    if (showFilters) {
      setHeaderTitle('filtra giocatori');
    } else {
      setHeaderTitle('team');
    }
  }, [showFilters])

  // set scroll
  useEffect(() => {
    if (showFilters) {
      document.querySelector(".filters-box").scrollTo(0, 0);
    }
    if (showPlayer) {
      document.querySelector(".player-single").scrollTo(0, 0);
    }
    if (showFilters || showPlayer) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showFilters, showPlayer])

  // browser back button
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if(!location.hash.includes('#')) {
        setShowPlayer(false)
      }
    })
    return function cleanup() {
      unlisten()
    }
  }, [history])  

  // open player
  function openPlayer(id) {
    let playerByID = playersList.filter(player => {
      return player.player_id === id;
    })
    let singlePlayer = playerByID[0];
    let playerData = singlePlayer.player.data;
    setPlayerSingle({ 'data': playerData, 'stats': singlePlayer });
    setHeaderTitle(playerData.fullname);
    setShowPlayer(true);
    history.push(`/team#${id}`);
  }

  // apply filters
  function applyFilters(input) {
    //input positions
    const positionsSelected = [];
    Object.entries(input.positions).map(([key, data], index) => (
      (data.checked) ? positionsSelected.push(index + 1) : null
    ));
    // set state
    setAppliedFilters({ keyword: input.keyword, positions: positionsSelected });
    setShowFilters(false);
  }

  // show screen
  const showScreen = (screen, isOpen) => {
    switch (screen) {
      case ('filters'):
        setShowFilters(isOpen);
        break;
      case ('single'):
        setShowPlayer(isOpen);
        if (!isOpen) {
          setHeaderTitle('team');
        }
        break;
      default:
    }
  }

  return (
    <>
      <Header title={headerTitle} onIconClick={showScreen} filtersActive={showFilters} singleActive={showPlayer} />
      <main>
        {showSpinner && <Spinner />}
        <PlayersFilter onSearchSubmit={applyFilters} isShown={showFilters} />
        <PlayersList players={playersList} filters={appliedFilters} isHidden={showPlayer} onPlayerSelected={openPlayer} />
        <PlayerSingle player={playerSingle} isShown={showPlayer} />
      </main>
    </>
  );

}

export default Team;