import moment from 'moment';
import 'moment/locale/it';

function getSourceFromURL(str) {
    let result = '';
    if (str) {
        let domain = (new URL(str));
        result = domain.hostname.replace('www.', '');
    }
    return result;
}

function calculateDifferenceBetweenDates(dateStart, utc) {

    let d1 = moment();
    let d2 = (utc) ? moment.utc(dateStart).local() : moment(dateStart);
    let t = '';

    if (d1 > d2) {
        t = 'past';
    } else {
        t = 'future';
    }

    let days = Math.abs(d2.diff(d1, 'days'));
    let hours = Math.abs(d2.diff(d1, 'hours'));
    let minutes = Math.abs(d2.diff(d1, 'minutes'));

    return { t: t, days: days, hours: hours, minutes: minutes };
}

function getDifferenceBetweenDates(dateStart) {

    const difference = calculateDifferenceBetweenDates(dateStart);
    const { t, days, hours, minutes } = difference;

    let label = '';
    let value = 0;

    if(minutes  === 0) {
        return 'ora';
    }

    // minutes
    if (minutes >= 1) {
        if (minutes === 1) {
            label = 'min.';
        } else {
            label = 'min.';
        }
        value = minutes;
    }  
    
    // hours
    if (hours >= 1) {
        if (hours === 1) {
            label = 'ora';
        } else {
            label = 'ore';
        }
        value = hours;
    }
    
    // days
    if (days >= 1) {
        if (days === 1) {
            label = 'giorno';
        } else {
            label = 'giorni';
        }
        value = days;
    }    

    if (t === 'past') {
        return `${value} ${label} fa`;
    } else {
        return `tra ${value} ${label}`;
    }

}

function getPlayerImageURL(playerNumber) {
    return `/images/team/${String(playerNumber).padStart(2, '0')}.jpg`
}

function formatDate(date, format) {
    return moment(date, format).locale("en").format("D MMM YYYY").toUpperCase();
}

function formatTime(date_time) {
    return moment.utc(date_time, "YYYY-MM-DD hh:mm:ss").local().format("HH:mm")
}

function isToday(date) {
    return moment(date).isSame(moment(), 'day');
}

function getAge(birthdate) {
    return moment().diff(moment(birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'), 'years');
}

function getYearMonth(date) {
    return moment(date).format('MM-YYYY');
}

const Utilities = {
    calculateDifferenceBetweenDates,
    getDifferenceBetweenDates,
    getSourceFromURL,
    getPlayerImageURL,
    formatDate,
    formatTime,
    isToday,
    getAge,
    getYearMonth
};

export default Utilities;