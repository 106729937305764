import { Component } from 'react';

class Logo extends Component {

  render() {
    return (
        <div id="logo">
            <img src="/icons/logo.svg" alt="Calcio Napp" />
            <span>Only Celtic Fans</span>
        </div>
    );
  }
}

export default Logo;