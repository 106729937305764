import { useState } from "react";
import { FormGroup, FormControlLabel, Checkbox, TextField } from "@mui/material";

const PlayersFilter = ({ onSearchSubmit, isShown }) => {

    const [favoritesInput, setFavoritesInput] = useState(false);
    const [keywordInput, setKeywordInput] = useState('');
    const [positionsInput, setPositionsInput] = useState(
        {
            type1: {
                label: "portieri",
                checked: true
            },
            type2: {
                label: "difensori",
                checked: true
            },
            type3: {
                label: "centrocampisti",
                checked: true
            },
            type4: {
                label: "attaccanti",
                checked: true
            }
        });

    const filterPlayers = () => {
        onSearchSubmit(
            {
                favorites: favoritesInput,
                keyword: keywordInput,
                positions: positionsInput
            })
    }

    const resetFilters = () => {
        setFavoritesInput(false);
        setKeywordInput('');
        setPositionsInput({
            type1: {
                label: "goalkeepers",
                checked: true
            },
            type2: {
                label: "defenders",
                checked: true
            },
            type3: {
                label: "midfielders",
                checked: true
            },
            type4: {
                label: "strikers",
                checked: true
            }
        });
    }

    const handleOnChange = (evt) => {
        if (evt.target.name === "positions") {
            setPositionsInput({ ...positionsInput, [evt.target.value] : { label : positionsInput[evt.target.value].label, checked: evt.target.checked }})
        } else if (evt.target.name === "keyword") {
            setKeywordInput(evt.target.value);
        } else if (evt.target.name === "favorites") {
            setFavoritesInput(evt.target.checked);
        }
    }

    return (
        <div className={isShown ? "filters-box slide slide-bottom has-header active" : "filters-box slide slide-bottom has-header"}>
            <div className="wrapper">
                {/* <div className="field">
                    <h2>Mostra solo favoriti</h2>
                    <FormGroup>
                        <FormControlLabel control={<Switch name="favorites" onChange={evt => handleOnChange(evt)} />} checked={favoritesInput} label={favoritesInput ? 'si' : 'no'} />
                    </FormGroup>
                </div> */}
                <div className="field">
                    <h2>Filter roles</h2>
                    <FormGroup>
                        {Object.entries(positionsInput).map(([key, data]) => (
                            <FormControlLabel key={key} control={<Checkbox name="positions" value={key} checked={data.checked} onChange={evt => handleOnChange(evt)} />} label={data.label} />
                        ))}
                    </FormGroup>
                </div>
                <div className="field">
                    <h2>Search by player's name</h2>
                    <TextField name="keyword" label="type player name" type="search" value={keywordInput} onChange={evt => handleOnChange(evt)} />
                </div>
                <div className="buttons">
                    <button className="primary" onClick={filterPlayers}>apply</button>
                    <button className="secondary" onClick={resetFilters}>azzera</button>
                </div>
            </div>
        </div>
    );
}

export default PlayersFilter;
