import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'

// components
import Header from "../components/Header";
import Spinner from "../components/Spinner";

//screens
import ArticlesFilter from "../screens/articles/Filters";
import ArticlesList from "../screens/articles/List";
import ArticleSingle from "../screens/articles/Single";

// node_modules
import axios from "axios";
import QueryString from "qs";

// utilities
import Utilities from "../Utilities";

const Articles = () => {

  // manage stored data
  const [articlesList, setArticlesList] = useState(null);
  const [articleSingle, setArticleSingle] = useState([]);
  const [articleCover, setArticleCover] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [headerTitle, setHeaderTitle] = useState('news');
  // manage show/hide screens
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showArticle, setShowArticle] = useState(false);

  // history
  const history = useHistory();

  // load cover
  useEffect(() => {
    const url = `${process.env.REACT_APP_BACK_URL}/api/cover`;
    axios.get(url)
      .then(response => {
        setArticleCover(response.data.result);
      })
  }, [])

  // load articles
  useEffect(() => {
    function loadArticles() {
      let appliedArchived = '';
      if (localStorage.getItem('archived')) {
        appliedArchived = localStorage.getItem('archived');
      }
      const url = `${process.env.REACT_APP_BACK_URL}/api/articles/${currentPage}`;
      const query = QueryString.stringify({ filters: appliedFilters, archived: appliedArchived });
      const config = { headers: { 'content-type': 'application/x-www-form-urlencoded' } };

      setShowSpinner(true);
      axios.post(url, query, config)
        .then(response => {
          setArticlesList(response.data.result);
          setShowFilters(false);
          setShowSpinner(false);
        })
    }
    loadArticles();
  }, [currentPage, appliedFilters])

  // set title and scroll
  useEffect(() => {
    if (showFilters) {
      setHeaderTitle('search');
    } else {
      setHeaderTitle('news');
    }
  }, [showFilters, currentPage])

  // set scroll
  useEffect(() => {
    if (showFilters) {
      document.querySelector(".filters-box").scrollTo(0, 0);
    }    
    if (showFilters || showArticle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }    
    if (currentPage === 0) {
      window.scrollTo(0, 0);
    }
  }, [showFilters, showArticle, currentPage])


  // detect scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  // browser back button
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!location.hash.includes('#')) {
        setShowArticle(false)
      }
    })
    return function cleanup() {
      unlisten()
    }

  }, [history])

  // reset title
  useEffect(() => {
    if (!showArticle) {
      setHeaderTitle('news');
      history.push('/articles');
    }
  }, [showArticle, history])

  // handle scroll
  function handleScroll() {
    let currentScroll = Math.ceil(window.innerHeight + document.documentElement.scrollTop);
    let bottomScroll = Math.floor(document.documentElement.offsetHeight);
    if (currentScroll < bottomScroll) {
      return;
    } else {
      setCurrentPage(currentPage + 1);
    }
  }

  // store applied filters
  function storeAppliedFilters(input) {
    setCurrentPage(0);
    setAppliedFilters(input);
  }

  function loadArticle(id) {
    setArticleSingle([]);
    const url = `${process.env.REACT_APP_BACK_URL}/api/article/${id}`;
    axios.post(url)
      .then(response => {
        setHeaderTitle(Utilities.getSourceFromURL(response.data.result.href));
        setArticleSingle(response.data.result);
        setShowArticle(true)
        history.push(`/articles#${id}`);
      })
  }

  // show screen
  const showScreen = (screen, isOpen) => {
    switch (screen) {
      case ('filters'):
        setShowFilters(isOpen);
        break;
      case ('single'):
        setShowArticle(isOpen);
        break;
      default:
    }
  }

  return (
    <>
      <Header title={headerTitle} onIconClick={showScreen} filtersActive={showFilters} singleActive={showArticle} />
      <main>
        {showSpinner && <Spinner />}
        <ArticlesFilter onSearchSubmit={storeAppliedFilters} isShown={showFilters} />
        <ArticlesList cover={articleCover} articles={articlesList} isHidden={showArticle} onArticleSelected={loadArticle} />
        <ArticleSingle article={articleSingle} isShown={showArticle} />
      </main>
    </>
  );
}

export default Articles;