import { BrowserRouter as Router } from 'react-router-dom'
import { useMediaQuery } from "react-responsive";

import Navbar from './components/Navbar';
import Logo from './components/Logo';

/* mui */
import {
  createTheme,
  ThemeProvider
} from "@mui/material";

const App = () => {

  /* react responsive media queries */
  const currentMedia = useMediaQuery({ query: "(max-width: 1023px)" }) ? 'mobile' : 'desktop'

  /* MUI customization */
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Titillium Web',
        'sans-serif'
      ].join(','),
    },
    media: currentMedia
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={`App ${currentMedia}`}>
        { currentMedia === 'desktop' &&
          <Logo />
        }
        <Router>
          <Navbar />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
