import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const Header = ({ title, onIconClick, filtersActive, singleActive }) => {

  return (
    <header>
      {singleActive &&
        <>
          <div className="buttons">
            <ArrowBackRoundedIcon fontSize="large" onClick={() => onIconClick('single', false)} />
          </div>
          <div className="title">
            <h1>{title}</h1>
          </div>
        </>
      }
      {!singleActive &&
        <>
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className="buttons">
            {filtersActive &&
              <HighlightOffIcon fontSize="large" onClick={() => onIconClick('filters', false)} />
            }
            {!filtersActive &&
              <>
                {/* <PersonRoundedIcon fontSize="large" /> */}
                <SearchRoundedIcon fontSize="large" onClick={() => onIconClick('filters', true)} />
              </>
            }
          </div>
        </>
      }
    </header>
  );

}

export default Header;