const Standings = ({ isVisible, standing }) => {

    return (
        <div className={isVisible ? `standing active` : `standing not-active`}>

            <h2>Classifica</h2>
            {standing.length > 0 &&
                <>
                    <div className="table">
                        <div className="row">
                            <div></div>
                            <div></div>
                            <div>GI</div>
                            <div>VI</div>
                            <div>PA</div>
                            <div>PE</div>
                            <div>DR</div>
                            <div>PT</div>
                        </div>
                        {standing.map(row => (
                            <div key={row.team_name.toLowerCase()} className={`row ${row.team_name.toLowerCase()}`}>
                                <div><img src={row.team.data.logo_path} alt={row.team_name} /></div>
                                <div>{row.team_name}</div>
                                <div>{row.overall.games_played}</div>
                                <div>{row.overall.won}</div>
                                <div>{row.overall.draw}</div>
                                <div>{row.overall.lost}</div>
                                <div>{row.total.goal_difference}</div>
                                <div>{row.points}</div>
                            </div>
                        ))}
                    </div>
                </>
            }
            {standing.length === 0 &&
                <div className="placeholder">
                    nessuna classifica disponibile,<br />prova con un'altra competizione
                </div>
            }
        </div>
    )
}

export default Standings