import { useTheme } from "@mui/material";
import ArticlePreview from "./Preview";

const ArticlesList = ({ cover, articles, isHidden, onArticleSelected }) => {

  const theme = useTheme();

  const openArticle = (id) => {
    onArticleSelected(id)
  }

  return (
    <>
      {articles &&
        <div className={`articles-list slide-back ${isHidden ? "not-active" : ""}`}>
          {/* // MOBILE OPEN ARTICLE IN AN IFRAME */}
          {/* {theme.media === 'mobile' &&
            <div className="article-preview-wrapper" onClick={() => openArticle(cover.id)}>
              <ArticlePreview media="mobile" cover={true} article={cover} data-id={cover.id} key={cover.id} onClick={() => openArticle(cover.id)} />
            </div>
          }
          {theme.media === 'desktop' &&
            <div className="article-preview-wrapper">
              <a href={cover.href} target="_blank" rel="noreferrer">
                <ArticlePreview media="desktop" cover={true} article={cover} />
              </a>
            </div>
          } */}
          <div className="article-preview-wrapper">
            <a href={cover.href} target="_blank" rel="noreferrer">
              <ArticlePreview media="desktop" cover={true} article={cover} />
            </a>
          </div>
          {articles.map(article => (
            // MOBILE OPEN ARTICLE IN AN IFRAME
            // theme.media === 'mobile' ? (
            //   <div className="article-preview-wrapper" key={article.id} onClick={() => openArticle(article.id)}>
            //     <ArticlePreview media="mobile" cover={false} article={article} />
            //   </div>
            // ) : (
            //   <div className="article-preview-wrapper" key={article.id}>
            //     <a href={article.href} target="_blank" rel="noreferrer">
            //       <ArticlePreview media="desktop" cover={false} article={article} />
            //     </a>
            //   </div>
            // )
            <div className="article-preview-wrapper" key={article.id}>
              <a href={article.href} target="_blank" rel="noreferrer">
                <ArticlePreview media="desktop" cover={false} article={article} />
              </a>
            </div>
          ))}
          {articles.length === 0 &&
            <div className="wrapper">
              <div className="placeholder">
                nessun articolo da mostrare, <br />utilizza altri fitri
              </div>
            </div>
          }
        </div>
      }
    </>
  );
}

export default ArticlesList;