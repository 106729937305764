import { useState, useEffect } from "react";
// theme
import { useTheme } from "@mui/material";
// utilities
import Utilities from "../../Utilities";
// components
import Scores from "./Scores";
import Scorers from "./Scorers";
import Tabs from "./Tabs";
import Stats from "./Stats";
import Lineup from './Lineup';
import Events from './Events';
import Comments from './Comments';
import Standings from './Standings';
// node modules
import ReactPlayer from "react-player";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import axios from "axios";
import QueryString from "qs";

const FixtureSingle = ({ leagues, fixture, isShown, onFixtureUpdate }) => {

  const [fixtureData, setFixtureData] = useState(fixture);
  const [clipLocation, setClipLocation] = useState(null);
  const [clipsList, setClipsList] = useState([]);
  const [scorersVisibility, setScorersVisibility] = useState(false);
  const [seasonStanding, setSeasonStanding] = useState([]);
  const [tabsVisibility, setTabsVisibility] = useState(
    [
      { label: 'lineup', visible: true },
      { label: 'events', visible: false },
      { label: 'comments', visible: false },
      { label: 'stats', visible: false },
      { label: 'standings', visible: false }
    ]);

  const {
    id,
    season_id,
    group_id,
    localTeam,
    visitorTeam,
    scores,
    time,
    stats,
    events,
    comments,
    formations,
    lineup,
    bench,
    highlights
  } = fixtureData;

  const theme = useTheme();

  function getTabVisibility(label) {
    return (tabsVisibility.find(tab => tab.label === label).visible)
  }

  function toggleTabsVisibility(label) {
    const updated = tabsVisibility.map(item => {
      if (item.label === label) {
        return { label: item.label, visible: true }
      } else {
        return { label: item.label, visible: false }
      }
    });
    setTabsVisibility(updated);

    const offsetHeader = (theme.media === 'mobile') ? 48 : 196;

    document.querySelector('.fixture-single').scrollTop = document.querySelector('.tabs-wrapper').offsetTop - offsetHeader;
  }

  function showVideoClip(event_id) {
    if (highlights.data.find(h => h.event_id === event_id)) {
      let location = highlights.data.find(h => h.event_id === event_id).location;
      setClipLocation(location);
    }
  }

  function getFixtureInfo() {
    const { time, league_id } = fixtureData;
    const info = [];
    if (time.status === 'LIVE') {
      info[0] = `${time.minute + time.added_time + time.extra_minute}'`
    } else if (time.status === 'HT') {
      info[0] = 'fine primo tempo'
    } else {
      info[0] = Utilities.formatDate(time.starting_at.date)
    }

    info[1] = leagues[league_id];
    return info;
  }

  // load fixture data
  useEffect(() => {
    if (Object.keys(fixture).length > 0) {
      setFixtureData(fixture);
    }

  }, [fixture]);

  // check clips availability
  useEffect(() => {
    if (highlights && time.status === 'FT' && isShown) {
      setScorersVisibility(false);
      const url = `${process.env.REACT_APP_BACK_URL}/api/clip`;
      const query = QueryString.stringify({ highlights: highlights.data });
      const config = { headers: { 'content-type': 'application/x-www-form-urlencoded' } };
      axios.post(url, query, config)
        .then(response => {
          setScorersVisibility(true);
          setClipsList(response.data.result);
        })
    } else {
      setScorersVisibility(true);
    }
  }, [fixture, highlights, time, isShown]);

  // update live match data
  useEffect(() => {
    if (Object.keys(fixtureData).length > 0) {
      let difference = Utilities.calculateDifferenceBetweenDates(time.starting_at.date_time);
      if (difference.t === 'past' && difference.hours < 48) {
        const interval = setTimeout(() => {
          console.log('live match updating')
          onFixtureUpdate(id, false).then(function (result) {
            setFixtureData(result);
          });
        }, 5000);
        if (time.status === 'FT') {
          clearInterval(interval);
        }
        return () => clearInterval(interval);
      }
    }
  }, [time, id, fixtureData, onFixtureUpdate]);

  // hide clip / tabs visibility
  useEffect(() => {
    if (!isShown) {
      setClipLocation(null);
      setTabsVisibility(
        [
          { label: 'lineup', visible: true },
          { label: 'events', visible: false },
          { label: 'comments', visible: false },
          { label: 'stats', visible: false },
          { label: 'standings', visible: false }
        ]);
    }
  }, [isShown]);

  // load standings
  useEffect(() => {
    if (season_id) {
      let url = `https://soccer.sportmonks.com/api/v2.0/standings/season/${season_id}?api_token=${process.env.REACT_APP_SPORTMONKS_TOKEN}&include=standings.team`;
      if (group_id) {
        url += `&group_id=${group_id}`;
      }
      axios.get(url)
        .then(response => {
          setSeasonStanding(response.data.data[0].standings.data);
        })
        .catch(() => {
          setSeasonStanding([]);
      })
    }
  }, [season_id, group_id])

  return (
    <div className={`fixture-single slide slide-right has-header ${isShown ? "active" : ""}`}>
      {localTeam && visitorTeam && scores && stats &&
        <>
          {clipLocation &&
            <div className="clip">
              <HighlightOffIcon className="close" fontSize="large" onClick={() => setClipLocation(null)} />
              <ReactPlayer url={clipLocation} controls={true} width="100%" height="100%" />
            </div>
          }
          <div className="header">
            <div className="info">
              {getFixtureInfo()[0]}
            </div>
            <div className="info">
              {getFixtureInfo()[1]}
            </div>
          </div>
          <div className="wrapper">
            {/* scores */}
            <Scores status={time.status} scores={scores} localTeam={localTeam} visitorTeam={visitorTeam} />
            {/* progress */}
            {time.status === 'LIVE' &&
              <div className="live">
                <img src="icons/progress.svg" alt="live" />
              </div>
            }
          </div>
          <div className="wrapper">
            {/* Scorers */}
            <Scorers status={time.status} events={events.data} clips={clipsList} onEventClick={showVideoClip} isVisible={scorersVisibility} />
          </div>
          {/* tabs */}
          <Tabs tabs={tabsVisibility} onTabClick={toggleTabsVisibility} />
          <div className="wrapper tabs-wrapper">
            {/* lineup */}
            <Lineup isVisible={getTabVisibility('lineup')} formations={formations} lineup={lineup} bench={bench.data} events={events.data} localTeam={localTeam} visitorTeam={visitorTeam} />
            {/* events */}
            <Events isVisible={getTabVisibility('events')} events={events.data} />
            {/* comments */}
            <Comments isVisible={getTabVisibility('comments')} comments={comments.data} />
            {/* stats */}
            <Stats isVisible={getTabVisibility('stats')} stats={stats} localTeam={localTeam} visitorTeam={visitorTeam} />
            {/* standing */}
            <Standings isVisible={getTabVisibility('standings')} standing={seasonStanding} />
          </div>
        </>
      }
    </div>
  );
}

export default FixtureSingle;