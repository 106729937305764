const Icons = ({ label }) => {

        switch (label) {
            case 'news':
              return (
                <svg width="192px" height="192px" viewBox="0 0 192 192">
                <path d="M88.7,4.5v29.3h14.6V4.5H88.7z M58.2,15.1L46,23.2l14.6,22l12.2-8.1L58.2,15.1z
                                M133.8,15.1l-14.6,22l12.2,8.1l14.6-22L133.8,15.1z M37.5,55.8c-12.1,0.1-21.9,9.9-22,22v102.4h14.6v7.3h14.6v-7.3h102.4v7.3h14.6
                                v-7.3h14.6V77.7c-0.1-12.1-9.9-21.9-22-22L37.5,55.8z M37.5,70.4h117.1c4-0.1,7.3,3.1,7.3,7.1c0,0.1,0,0.2,0,0.2v87.8H30.1V77.7
                                c-0.1-4,3.1-7.3,7.1-7.3C37.3,70.4,37.4,70.4,37.5,70.4z M70.4,85C52.2,85,37.5,99.8,37.5,118s14.7,32.9,32.9,32.9
                                s32.9-14.7,32.9-32.9l0,0C103.3,99.8,88.5,85.1,70.4,85z M136.2,92.3c-6.1,0-11,4.9-11,11c0,6.1,4.9,11,11,11s11-4.9,11-11
                                C147.2,97.3,142.3,92.3,136.2,92.3z M70.4,99.7c5.8,0,11.2,2.7,14.6,7.3H55.8C59.2,102.4,64.6,99.6,70.4,99.7z M52.5,114.3h35.8
                                c0.5,2.4,0.5,4.9,0,7.3H52.5C52,119.2,52,116.7,52.5,114.3z M136.2,121.6c-6.1,0-11,4.9-11,11c0,6.1,4.9,11,11,11s11-4.9,11-11l0,0
                                C147.2,126.5,142.3,121.6,136.2,121.6z M55.8,128.9H85c-6,8.1-17.5,9.7-25.5,3.7C58.1,131.6,56.8,130.3,55.8,128.9z"/>
                </svg> 
              );        
            case 'fixtures':
                return (
                    <svg width="192px" height="192px" viewBox="0 0 192 192">
                        <path d="M69.3,6.6c-0.9-0.1-1.9,0.1-2.7,0.5c0,0-13.6,5-17.7,6.3c-9.7,2.3-18.5,7.3-25.4,14.5
                        C14,37.4,6.3,53.2,6.3,77.2v11.3c0,11.7,9.5,21.3,21.2,21.3c0,0,0,0,0.1,0h11.8v54.3c0,11.7,9.5,21.3,21.2,21.3c0,0,0,0,0.1,0h70.6
                        c11.7,0,21.3-9.5,21.3-21.2c0,0,0,0,0-0.1v-54.3h11.8c11.7,0,21.3-9.5,21.3-21.2c0,0,0,0,0-0.1V77.2c0-24-7.7-39.9-17.2-49.4
                        c-7.1-6.9-15.8-11.9-25.4-14.5C139,12,125.5,7,125.5,7c-3.6-1.4-7.7,0.5-9.1,4.1l0,0C113.4,19.6,105,25,96,24.2
                        c-9,0.7-17.3-4.6-20.4-13.1C74.7,8.4,72.1,6.6,69.3,6.6z M66.1,22c5.8,11,17.5,17.5,29.9,16.8c12.4,0.6,24-5.9,29.9-16.8
                        c4.1,1.4,9.5,3.6,13.1,4.5c7.3,1.8,14.1,5.6,19.5,10.9c6.8,6.8,13.1,18.6,13.1,39.4v11.3c0,4-3.1,7.2-7.1,7.2c-0.1,0-0.1,0-0.2,0
                        h-11.8V79c0.1-3.9-2.9-7.1-6.8-7.3c-3.9-0.1-7.1,2.9-7.3,6.8l0,0v85.2c0,4-3.1,7.2-7.1,7.2c-0.1,0-0.1,0-0.2,0H60.7
                        c-4,0-7.2-3.1-7.2-7.1c0-0.1,0-0.1,0-0.2V79c0.1-3.9-2.9-7.1-6.8-7.2l0,0c-3.7,0-6.8,3-6.8,6.8c0,0.2,0,0.3,0,0.5v16.3H27.6
                        c-4,0-7.2-3.1-7.2-7.1c0-0.1,0-0.1,0-0.2V76.8c0-21.3,6.3-32.6,13.1-39.4c5.4-5.3,12.1-9,19.5-10.9l0,0C56.6,25.6,62,23.3,66.1,22
                        L66.1,22z"/>
                        <g transform="translate(18.071 18.574)">
                            <rect x="55.8" y="53" width="11.1" height="62.1" />
                            <path d="M104.7,115.1H75.4V53h29.3V115.1z M85.7,104.9h8.7V63.3h-8.7V104.9z" />
                        </g>
                    </svg>                    
                );
            case 'team':
                return (
                    <svg width="192px" height="192px" viewBox="0 0 192 192">
                        <path d="M162.1,19.3l-30.4,38l11.9,9.5l30.4-37.9L162.1,19.3z M115.1,29.7l-7.6,30.4l14.7,3.8
                    l7.6-30.4L115.1,29.7z M16.2,77.2C7.8,77.2,1.1,84,1,92.3v34.2c0.1,10.5,8.5,18.9,19,19c7.8-0.4,14.5-5.7,16.6-13.3l26.3,8.1
                    c3.1,31,30.8,53.6,61.8,50.5c25.2-2.6,45.5-21.5,49.9-46.4c1.5,0.7,3.1,1.1,4.7,1.2c6.3,0.1,11.5-4.9,11.6-11.2s-4.9-11.5-11.2-11.6
                    c-0.1,0-0.3,0-0.4,0c-1.6,0.1-3.3,0.5-4.7,1.2c-5.8-27.4-30.1-46.9-58.1-46.7h-9.3v15.2h9.3c23.1,0,43.2,17.9,43.9,40.6
                    c0.6,23.1-17.5,42.3-40.6,42.9c-23.1,0.6-42.3-17.5-42.9-40.6c0-0.4,0-0.8,0-1.2v-5.7l-5.5-1.7L39,117v-9.5H23.8v19
                    c0,2.1-1.7,3.8-3.8,3.8s-3.8-1.7-3.8-3.8l0,0V92.3h68.3v22.8h15.2v-38H16.2z"/>
                    </svg>
                );
            default:
              return null;
          }

}

export default Icons;
