import { useState } from "react";

/* mui */
import {
    Snackbar,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const FixturesFilter = ({ onSearchSubmit, isShown }) => {

    const [snackOpen, setSnackOpen] = useState(false);
    const [keywordInput, setKeywordInput] = useState('');
    const [leaguesInput, setLeaguesInput] = useState({
        type1: {
            label: "Premiership",
            checked: true,
            id: 501
        },
    });

    const filterFixtures = () => {
        let leaguesChecked = document.querySelectorAll('input[name="leagues"]:checked').length;
        if (leaguesChecked === 0) {
            setSnackOpen(true);
        } else {
            onSearchSubmit(
                {
                    keyword: keywordInput,
                    leagues: leaguesInput
                })
        }
    }

    const resetFilters = () => {
        setKeywordInput('');
        setLeaguesInput({
            type1: {
                label: "Premiership",
                checked: true,
                id: 501
            },
        });
    }

    const handleOnChange = (evt) => {
        if (evt.target.name === 'leagues') {
            setLeaguesInput({ ...leaguesInput, [evt.target.value]: { label: leaguesInput[evt.target.value].label, checked: evt.target.checked, id: leaguesInput[evt.target.value].id } })
        }
        else if (evt.target.name === "keyword") {
            setKeywordInput(evt.target.value);
        }
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <>
            <div className={isShown ? "filters-box slide slide-bottom has-header active" : "filters-box slide slide-bottom has-header"}>
                <div className="wrapper">
                    <div className="field">
                        <h2>Filter competitions</h2>
                        <FormGroup>
                            {Object.entries(leaguesInput).map(([key, data]) => (
                                <FormControlLabel key={key} control={<Checkbox name="leagues" value={key} checked={data.checked} onChange={evt => handleOnChange(evt)} />} label={data.label} />
                            ))}
                        </FormGroup>
                    </div>
                    <div className="field">
                        <h2>Search by opposing team's name</h2>
                        <TextField name="keyword" label="type the name of the opposing team" type="search" value={keywordInput} onChange={evt => handleOnChange(evt)} />
                    </div>
                    <div className="buttons">
                        <button className="primary" onClick={filterFixtures}>apply</button>
                        <button className="secondary" onClick={resetFilters}>azzera</button>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackOpen}
                autoHideDuration={3000}
                onClose={handleSnackClose}
                message="effettua almeno una selezione"
                action={action}
            />
        </>
    );
}

export default FixturesFilter;
