import { useState, useEffect } from 'react';

import {
    Route,
    Redirect,
    Link,
    Switch,
    useLocation,
    useHistory
} from 'react-router-dom'

/* pages */
import Articles from '../pages/Articles'
import Team from '../pages/Team';
import Fixtures from '../pages/Fixtures'

/* components*/
import Icons from './Icons';

function Navbar() {

    console.log(window.location.pathname);

    const [locationKeys, setLocationKeys] = useState(useLocation.pathname);

    const menuItems = [
        { label: 'news', location: '/articles' },
        { label: 'fixtures', location: '/fixtures' },
        { label: 'team', location: '/team' },
    ];

    const history = useHistory();

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'POP') {
                setLocationKeys(location.pathname)
            }
        })
    }, [locationKeys, history])

    return (
        <>
            <nav>
                <ul>
                    {menuItems.map((item, index) =>
                        <li key={index} onClick={() => setLocationKeys(item.location)} className={(item.location === locationKeys || item.location === window.location.pathname) ? "active" : "not-active"}>
                            <Link to={item.location}>
                                <Icons label={item.label} />
                                <span className="label">{item.label}</span>
                            </Link>
                        </li>
                    )}
                </ul>
            </nav>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/articles" />
                </Route>
                <Route exact path="/articles">
                    <Articles />
                </Route>
                <Route exact path="/fixtures">
                    <Fixtures />
                </Route>
                <Route exact path="/team">
                    <Team />
                </Route>
            </Switch>
        </>
    );
}

export default Navbar;