import { useEffect, useLayoutEffect, useState } from "react";

/* mui */
import {
  useTheme,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";

/* utilities */
import Utilities from "../../Utilities";
// components
import FixturePreview from "./Preview";

const FixturesList = ({ fixtures, filters, isHidden, onFixtureSelected }) => {

  const theme = useTheme();

  const [highlightMatch, setHighlightMatch] = useState(null);
  const [rowsVisible, setRowsVisible] = useState(null);
  const [fixturesCalendar, setFixturesCalendar] = useState('past');

  /* sort fixtures */
  fixtures.sort(function (x, y) {
    let firstTerm;
    let secondTerm;
    let a = new Date(x.time.starting_at.date), b = new Date(y.time.starting_at.date);
    if (fixturesCalendar === 'past') {
      firstTerm = b;
      secondTerm = a;
    } else if (fixturesCalendar === 'future') {
      firstTerm = a;
      secondTerm = b;
    }
    return firstTerm - secondTerm;
  });

  // year months
  const yearMonths = []

  /* aggregate months */
  fixtures.forEach(fixture => {
    if (fixtureShown(fixture)) {
      yearMonths.push(Utilities.getYearMonth(fixture.time.starting_at.date))
    }
  });

  let uniqueYearMonths = [...new Set(yearMonths)];

  /* if fixture is shown */
  function fixtureShown(fixture) {
    let checkCalendar;
    if (fixturesCalendar === 'past') {
      checkCalendar = ["FT", "AET", "FT_PEN"].includes(fixture.time.status)
    } else if (fixturesCalendar === 'future') {
      checkCalendar = ["NS", "TBA"].includes(fixture.time.status)
    }
    if (checkCalendar && (!highlightMatch || highlightMatch.id !== fixture.id) && (fixture.visitorTeam.data.name.toLowerCase().includes(filters.keyword.toLowerCase()) || fixture.localTeam.data.name.toLowerCase().includes(filters.keyword.toLowerCase())) && filters.leagues.includes(fixture.league_id)) {
      return true;
    }
    return false;
  }

  /* set new calendar */
  const handleChange = (event, calendar) => {
    if (calendar !== null) {
      setFixturesCalendar(calendar);
    }
  };

  /* check playing match */
  useEffect(() => {

    if (Object.keys(fixtures).length > 0) {

      // next match
      const nextMatches = fixtures.filter(function (fixture) {
        return fixture.time.status === 'NS' || fixture.time.status === 'TBA';
      });

      // check playing match
      const playingMatch = fixtures.find(function (fixture) {
        let difference = Utilities.calculateDifferenceBetweenDates(fixture.time.starting_at.date_time, true);
        return ((fixture.time.status === 'LIVE' || fixture.time.status === 'HT') || (difference.t === 'future' && difference.hours < 1) || (difference.t === 'past' && difference.hours < 3));
      });
      if (playingMatch) {
        console.log('playing match');
        const interval = setInterval(() => {
          console.log('highlight match updating');
          onFixtureSelected(playingMatch.id, false).then(function (result) {
            setHighlightMatch(result);
            if (result.time.status === 'FT') {
              clearInterval(interval);
            }
          });
        }, 5000);
        onFixtureSelected(playingMatch.id, false).then(function (result) {
          setHighlightMatch(result);
        });
        return () => clearInterval(interval);
      } else {
        let nextMatch;
        if (fixturesCalendar === 'past') {
          nextMatch = nextMatches[nextMatches.length - 1]
        } else if (fixturesCalendar === 'future') {
          nextMatch = nextMatches[0]
        }
        setHighlightMatch(nextMatch);
      }
    }
  }, [fixtures, onFixtureSelected, fixturesCalendar])

  /* check at least on result */
  useLayoutEffect(() => {
    setRowsVisible(document.querySelectorAll('.fixture-preview-wrapper').length);
  }, [filters, fixturesCalendar])

  return (

    <div className={`fixtures-list slide-back ${isHidden ? "not-active" : ""}`}>
      <>
        {highlightMatch &&
          <div className="fixture-preview-wrapper" key={highlightMatch.id} onClick={() => (highlightMatch.time.status !== 'AA') ? onFixtureSelected(highlightMatch.id, true) : console.log('not started')}>
            <div className="wrapper">
              <FixturePreview media={theme.media} key={highlightMatch.id} fixture={highlightMatch} highlight={true} />
            </div>
          </div>
        }
        <div className="wrapper">
          <ToggleButtonGroup
            color="primary"
            value={fixturesCalendar}
            exclusive
            onChange={handleChange}
            sx={{
              width: '100%'
            }}
          >
            <ToggleButton value="past">past</ToggleButton>
            <ToggleButton value="future">future</ToggleButton>
          </ToggleButtonGroup>
          {rowsVisible === 1 &&
            <div className="placeholder">
              no match to show, <br />change the way you filter
            </div>
          }
          {uniqueYearMonths.map(yearMonth => (
            <div key={yearMonth}>
              <h2 key={yearMonth}>{Utilities.formatDate(yearMonth, 'MM-YYYY').substring(2)}</h2>
              {fixtures.map(fixture => (
                fixtureShown(fixture) && Utilities.getYearMonth(fixture.time.starting_at.date_time) === yearMonth &&
                <div className="fixture-preview-wrapper" key={fixture.id} onClick={() => onFixtureSelected(fixture.id, true)}>
                  <FixturePreview media={theme.media} key={fixture.id} fixture={fixture} highlight={false} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </>

    </div>

  );
}

export default FixturesList;
