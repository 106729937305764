import Utilities from "../../Utilities";

const FixturePreview = ({ media, fixture, highlight }) => {

    const { localTeam, visitorTeam, scores, time } = fixture;

    let scoresDisplayed = [scores.localteam_score, scores.visitorteam_score];

    if (fixture.time.status === 'NS' || fixture.time.status === 'TBA') {
        scoresDisplayed = ['-', '-']
    }

    function getTvStation() {
        let tvstation = '';
        if (fixture.tvstations.data) {
            if (fixture.tvstations.data.find(t => t.tvstation === 'DAZN Italia')) {
                tvstation += ' (DAZN) '
            }
            if (fixture.tvstations.data.find(t => t.tvstation === 'Sky Sport (ITA)')) {
                tvstation += ' (Sky) '
            }
        }
        return tvstation
    }

    function getMatchStatus() {
        let status;
        switch (time.status) {
            case 'LIVE':
                status = `LIVE (${time.minute + time.added_time + time.extra_minute + time.injury_time}')`
                break;
            case 'HT':
                status = 'half time'
                break;
            case 'FT':
            case 'AET':
            case 'FT_PEN':
                status = (highlight) ? 'full time' : Utilities.formatDate(time.starting_at.date)
                break;
            case 'NS':
            case 'TBA':
                if (Utilities.isToday(time.starting_at.date)) {
                    status = `today at ${Utilities.formatTime(time.starting_at.date_time)} ${getTvStation()}`
                } else {
                    status = time.starting_at.time !== '00:00:00' ? `${Utilities.formatDate(time.starting_at.date)} (${Utilities.formatTime(time.starting_at.date_time)})` : Utilities.formatDate(time.starting_at.date);
                }
                break;
            default:
                status = 'date to be fixed'
        }
        return status;
    }

    return (
        <div className="fixture-preview">
           
            <div className="score">
                <div className={`team ${localTeam.data.short_code}`}>
                    <p>{media === "mobile" ? localTeam.data.short_code : localTeam.data.name}</p>
                    <p>{scoresDisplayed[0]}</p>
                </div>
                <div className={`team ${visitorTeam.data.short_code}`}>
                    <p>{media === "mobile" ? visitorTeam.data.short_code : visitorTeam.data.name}</p>
                    <p>{scoresDisplayed[1]}</p>
                </div>
            </div>
            <p className="little">{getMatchStatus()}</p>
            {time.status === 'LIVE' &&
                <div className="live">
                    <img src="icons/progress.svg" alt="live" />
                </div>
            }
        </div>
    );
}

export default FixturePreview;