const Events = ({ isVisible, events, comments }) => {

    function getEventData(event) {
        let eventData;
        switch (event.type) {
            case 'yellowcard':
            case 'redcard':
            case 'yellowred':
                eventData = <>{event.player_name}<br /><span className="little">{event.reason}</span></>;
                break;
            case 'substitution':
                eventData = <>{event.player_name}<br /><span className="little">{event.related_player_name}</span></>;
                break;
            case 'goal':
                eventData = <>{event.player_name} (<strong>{event.result}</strong>)<br />{event.related_player_name && <span className="little">assist {event.related_player_name}</span>}</>;
                break;
            case 'own-goal':
                eventData = <>{event.player_name} (<strong>{event.result}</strong>)<br /><span className="little">autogoal</span></>;
                break;
            case 'penalty':
                eventData = <>{event.player_name} (<strong>{event.result}</strong>)<br /><span className="little">rigore</span></>;
                break;
            case 'missed_penalty':
                eventData = <>{event.player_name}<br /><span className="little">rigore sbagliato</span></>;
                break;
            case 'var':
                eventData = <>{event.player_name}<br /><span className="little">goal annullato (<strong>VAR</strong>)</span></>;
                break;
            default:
                eventData = '';
        }
        return (eventData);
    }

    function getEventIcon(event) {
        let eventIcon;
        switch (event.type) {
            case 'goal':
            case 'penalty':
            case 'own-goal':
                eventIcon = 'ballgreen';
                break;
            case 'missed_penalty':
            case 'var':
                eventIcon = 'ballred';
                break;
            default:
                eventIcon = event.type;
        }
        return (eventIcon);
    }

    return (
        <div className={isVisible ? `events active` : `events not-active`}>
            <h2>Eventi</h2>
            {events.sort((a, b) => b.id - a.id).map(event => (
                <div className={`row ${event.team.data.short_code}`} key={event.id}>
                    <div className="data">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="big">{event.minute}'</td>
                                    <td>
                                        <img className="icon" src={`icons/${getEventIcon(event)}.svg`} alt={event.type} />
                                    </td>
                                    <td>{getEventData(event)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
            {events.length === 0 &&
                <div className="placeholder">
                    no event to show,<br />try later
                </div>
            }
        </div>
    )
}

export default Events