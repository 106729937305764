const Comments = ({ isVisible, comments }) => {

    function showMinute(comment) {
        const events =
            [
                'half starts',
                'half ended',
                'game finished'
            ];

        if (comment.minute > 0 && !events.some(substring => comment.comment.toLowerCase().includes(substring))) {
            return true;
        }
        return false;
    }

    function isFanComment(comment) {
        if (comment.toLowerCase().includes('celtic')) {
            return true;
        }
        return false;
    }

    return (
        <div className={isVisible ? `comments active` : `comments not-active`}>
            <h2>Comments</h2>
            {comments.sort((a, b) => b.order - a.order).map((comment, index) => (
                <div className={isFanComment(comment.comment) ? `row celtic` : `row`} key={index}>
                    {showMinute(comment) && <p className="minute">{comment.minute}'</p>}
                    <p>{comment.comment}</p>
                </div>
            ))}
            {comments.length === 0 &&
                <div className="placeholder">
                    no comment to show,<br />try later
                </div>
            }
        </div>
    )
}

export default Comments