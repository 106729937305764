import { useTheme } from "@mui/material";

const Player = ({ substitution, data, playersInLine }) => {

    const theme = useTheme();

    const playerStyle = (theme.media === 'mobile') ? {
        width: `calc(100% / ${playersInLine})`
    } : {
        width: `100%`
    };    

    return (
        <div className="player" style={playerStyle}>
            {data !== undefined &&
                <>
                    <img className="avatar" src={data.player.data.image_path} alt={data.player.data.common_name} />
                    <p>{data.player.data.display_name}</p>
                    {substitution &&
                        <img src="icons/outpitch.svg" alt="substitution" className="substitution" />
                    }
                </>
            }
        </div>
    );
}

export default Player;