const Bench = ({ bench, substitutions, localTeam, visitorTeam }) => {

    const localTeamBench = bench.filter(function (player) {
        return player.team_id === localTeam.data.id;
    });
    const visitorTeamBench = bench.filter(function (player) {
        return player.team_id === visitorTeam.data.id;
    });

    function checkPlayerInPitch(player_id) {
        let minute = null;
        minute = substitutions.filter(function (substitution) {
            return substitution.player_id === player_id;
        })
        return minute;
    }

    return (
        <div className="bench">
            <h2>Bench</h2>
            <div className="table">
                <div className="team">
                    <div className="row">
                        <div><strong>{localTeam.data.name}</strong></div>
                    </div>
                    {localTeamBench.map(player => (
                        <div className={checkPlayerInPitch(player.player_id).length > 0 ? 'row in-pitch' : 'row'} key={player.player_id}>
                            <div>{player.player.data.common_name}</div>
                            {checkPlayerInPitch(player.player_id).length > 0 &&
                                <>
                                    <div><img src="icons/inpitch.svg" alt={player.player_common_name} /></div>
                                    <div>{checkPlayerInPitch(player.player_id)[0].minute}'</div>
                                </>
                            }
                        </div>
                    ))}
                </div>
                <div className="team">
                <div className="row">
                        <div><strong>{visitorTeam.data.name}</strong></div>
                    </div>
                    {visitorTeamBench.map(player => (
                        <div className={checkPlayerInPitch(player.player_id).length > 0 ? 'row inPitch' : 'row'} key={player.player_id}>
                            <div>{player.player.data.common_name}</div>
                            {checkPlayerInPitch(player.player_id).length > 0 &&
                                <>
                                    <div><img src="icons/inpitch.svg" alt={player.player_common_name} /></div>
                                    <div>{checkPlayerInPitch(player.player_id)[0].minute}'</div>
                                </>
                            }
                        </div>
                    ))}
                </div>
            </div >


        </div >
    )
}

export default Bench