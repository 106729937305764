const PlayersList = ({ players, filters, isHidden, onPlayerSelected }) => {

    function playerShown(name, number, position) {
        if (number > 0 && name.toLowerCase().includes(filters.keyword.toLowerCase()) && filters.positions.includes(position)) {
            return true;
        }
        return false;
    }

  // player positiion
  const playerPositionLabels = ['', 'portiere', 'difensore', 'centrocampista', 'attaccante']

    return (
        <div className="wrapper">
            <div className={`players-list slide-back ${isHidden ? "not-active" : ""}`}>
                {players.sort((a,b) => a.position_id - b.position_id).map(player => (
                    playerShown(player.player.data.display_name, player.number, player.position_id) &&
                    <div className="player-preview" key={player.player_id} onClick={() => onPlayerSelected(player.player_id)}>
                        <img src={`${player.player.data.image_path}`} alt={player.player.data.display_name}></img>
                        <div className="text">
                            <div className="copy">
                                <p>{player.player.data.display_name}</p>
                                <p className="little">{playerPositionLabels[player.position_id]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

}

export default PlayersList;