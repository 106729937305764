import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'

// components
import Header from "../components/Header";
import Spinner from "../components/Spinner";

// screens
import FixturesList from "../screens/fixtures/List";
import FixturesFilter from "../screens/fixtures/Filters";
import FixtureSingle from "../screens/fixtures/Single";

// node_modules
import axios from "axios";

const Fixture = () => {

  // manage stored data
  const [fixturesList, setFixturesList] = useState([]);
  const [fixtureSingle, setFixtureSingle] = useState([]);
  const [headerTitle, setHeaderTitle] = useState('fixtures');
  const [appliedFilters, setAppliedFilters] = useState({ keyword: '', results: ['vinte', 'pareggiate', 'perse'], leagues: [501], calendar: 'past' });

  // manage show/hide screens
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [showFixture, setShowFixture] = useState(false);

  // history
  const history = useHistory();

  // leagues list
  const leaguesList = {
    501: 'Premiership',
  }

  // show screen
  const showScreen = (screen, isOpen) => {
    switch (screen) {
      case ('filters'):
        setShowFilters(isOpen);
        break;
      case ('single'):
        setShowFixture(isOpen);
        if (!isOpen) {
          setHeaderTitle('fixtures');
        }
        break;
      default:
    }
  }

  // load fixtures
  useEffect(() => {
    getFixturesList()
  }, [])

  // set title
  useEffect(() => {
    if (showFilters) {
      setHeaderTitle('filtra partite');
    } else {
      setHeaderTitle('fixtures');
    }
  }, [showFilters])

  // set scroll
  useEffect(() => {
    if (showFilters) {
      document.querySelector(".filters-box").scrollTo(0, 0);
    }
    if (showFixture) {
      document.querySelector(".fixture-single").scrollTo(0, 0);
    }
    if (showFilters || showFixture) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showFilters, showFixture])

  // browser back button
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if(!location.hash.includes('#')) {
        setShowFixture(false)
      }
    })
    return function cleanup() {
      unlisten()
    }
  }, [history])  

  function getTeams(data) {
    let { localTeam, visitorTeam } = data;
    return `${localTeam.data.short_code} - ${visitorTeam.data.short_code}`;
  }

  async function getFixtureData(id, showFixture) {
    const url = `https://soccer.sportmonks.com/api/v2.0/fixtures/${id}?api_token=${process.env.REACT_APP_SPORTMONKS_TOKEN}&include=visitorTeam,localTeam,venue,referee,stats,comments,events.team,lineup.player,bench.player,highlights,tvstations`;
    if (showFixture) {
      setShowSpinner(true);
      axios.get(url).then(response => {
        const fixture = response.data.data;
        setFixtureSingle(fixture);
        setHeaderTitle(getTeams(fixture));
        setShowFixture(true);
        setShowSpinner(false);
        history.push(`/fixtures#${id}`);
      })
    } else {
      const response = await axios.get(url);
      return (response.data.data);
    }
  }

  // get fixtures list
  function getFixturesList() {
    // ID napoli 597
    const url = `https://soccer.sportmonks.com/api/v2.0/fixtures/between/2022-08-01/2023-07-31/53?api_token=${process.env.REACT_APP_SPORTMONKS_TOKEN}&include=localTeam,visitorTeam,tvstations`;
    setShowSpinner(true);
    axios.get(url)
      .then(response => {
        const fixtures = response.data.data;
        setFixturesList(fixtures);
        setShowFilters(false);
        setShowSpinner(false);
      })
  }

  function applyFilters(input) {
    // input leagues
    const leaguesSelected = [];
    Object.entries(input.leagues).map(([key, data], index) => (
      (data.checked) ? leaguesSelected.push(data.id) : null
    ))
    // set state
    setAppliedFilters({ keyword: input.keyword, leagues: leaguesSelected });
    setShowFilters(false);
  }



  return (
    <>
      <Header title={headerTitle} onIconClick={showScreen} filtersActive={showFilters} singleActive={showFixture} />
      <main>
        {showSpinner && <Spinner />}
        <FixturesFilter isShown={showFilters} onSearchSubmit={applyFilters} />
        <FixturesList leagues={leaguesList} fixtures={fixturesList} filters={appliedFilters} isHidden={showFixture} onFixtureSelected={getFixtureData} />
        <FixtureSingle leagues={leaguesList} fixture={fixtureSingle} isShown={showFixture} onFixtureUpdate={getFixtureData} />
      </main>
    </>
  );

}

export default Fixture;