import { useState } from "react";
import { useTheme } from "@mui/material";

import Utilities from "../../Utilities";

const PlayerSingle = ({ player, isShown }) => {

  const [showImage, setShowImage] = useState(true);

  const { data, stats } = player;

  const theme = useTheme();

  let dataTableRows = null;
  let statsTableRows = null;
  let statsRowsVisible = null;

  if (Object.keys(data).length > 0 && Object.keys(stats).length > 0) {

    dataTableRows =
      [
        { label: 'height', value: data.height },
        { label: 'weight', value: data.weight }
      ];

    statsTableRows =
      [
        { label: 'minutes played', value: stats.minutes },
        { label: 'appearances: starter/substitute', value: `${stats.lineups} / ${stats.substitute_in}` },
        { label: 'goal', value: stats.goals },
        { label: 'owngoals', value: stats.owngoals },
        { label: 'assist', value: stats.assists },
        { label: 'saves: total / in box', value: `${stats.saves} / ${stats.inside_box_saves}` },
        { label: 'penalties saves', value: stats.penalties.saves },
        { label: 'dispossesed', value: stats.dispossesed },
        { label: 'intercepions', value: stats.intercepions },
        { label: 'tackles', value: stats.tackles },
        { label: 'blocks', value: stats.blocks },
        { label: 'cleansheet', value: stats.cleansheets },
        { label: 'crosses: totals / successful', value: `${stats.crosses.total} / ${stats.crosses.accurate}` },
        { label: 'cards: yellow /red', value: `${stats.yellowcards} / ${stats.redcards}` },
        { label: 'penalty fouls: won / committed', value: `${stats.penalties.won} / ${stats.penalties.committed}` },
        { label: 'fouls: committed / drawn', value: `${stats.fouls.committed} / ${stats.fouls.drawn}` },
        { label: 'dribbles: attempts / success', value: `${stats.dribbles.attempts} / ${stats.dribbles.success}` },
        { label: 'dribbled past', value: stats.dribbles.dribbled_past },
        { label: 'duels: total / won', value: `${stats.duels.total} / ${stats.duels.won}` },
        { label: 'passes: total / accuracy', value: `${stats.passes.total} / ${stats.passes.accuracy}%` },
        { label: 'key passes', value: stats.passes.key_passes },
        { label: 'penalties: scored / missed', value: `${stats.penalties.scores} / ${stats.penalties.missed}` },
        { label: 'shots: total / on target', value: `${stats.shots.shots_total} / ${stats.shots.shots_on_target}` },
      ];

    statsRowsVisible = statsTableRows.filter(function (row) {
      return getValue(row.value) !== '-' && getValue(row.value) !== '- / -' && getValue(row.value) !== '- / -%'
    });

  }



  function getValue(value) {
    if (value === undefined || value === null) {
      return '-'
    } else if (typeof (value) === 'string') {
      return value.replaceAll('null', '-')
    }
    return value;
  }

  return (
    <div className={`player-single slide slide-right has-header ${isShown ? "active" : ""}`}>
      {dataTableRows && statsTableRows &&
        <>
          <div className="preview cover player-single-preview">
            {showImage &&
              <img onLoad={() => { setShowImage(true) }} src={Utilities.getPlayerImageURL(stats.number)} key={data.display_name} alt={data.display_name} style={{ display: showImage ? 'block' : 'none' }} />
            }
            <div className="text">
              <div className="copy">
                <h2>#{stats.number} </h2>
              </div>
            </div>
            {theme.media === "desktop" &&
              <div className="text-float">
                <div className="copy">
                  <p>Born in {data.birthplace} ({data.birthcountry})<br />on {Utilities.formatDate(data.birthdate, 'DD/MM/YYYY')} ({Utilities.getAge(data.birthdate)} anni)<br />alto {data.height}, pesa {data.weight}.</p>
                </div>
              </div>
            }
          </div>
          <div className="wrapper">
            {theme.media === 'mobile' &&
              <>
                <h2>Info</h2>
                <p>Born at {data.birthplace} ({data.birthcountry})<br />on {Utilities.formatDate(data.birthdate, 'DD/MM/YYYY')} ({Utilities.getAge(data.birthdate)} anni)<br />alto {data.height}, pesa {data.weight}.</p>
              </>
            }
            <h2>Stats</h2>
            {statsRowsVisible.length > 0 &&
              <table>
                <tbody>
                  {statsRowsVisible.map(row => (
                    <tr key={row.label}>
                      <td>{row.label}</td>
                      <td>{getValue(row.value)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
            {statsRowsVisible.length === 0 &&
              <div className="placeholder">
                no stats to show,<br />try by another player
              </div>
            }
          </div>
        </>
      }
    </div>
  );

}

export default PlayerSingle;